<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import Ticket from '@/views/com/Ticket'
import Naves from '@/views/com/Naves'

export default {
  name: 'hotelHomestayDetail',
  data() {
    return {
      ...api.command.getState(),
      activeKey: 0,
      detail: {
        canBuy: '1',
        recommend: '0',
        thirdTicket: '0',
        joinFx: '0',
        confirmMethod: '0',
        saleCount: '',
        readCount: '',
        buyCount: '',
        nights: '',
        refundRate: '',
        refundExplain: '1',
        startTime: '23',
        startMinute: '59',
        saleCount: '',
        readCount: '',
        buyCount: '',
      },
      isShowSnapshot: false,
      storeData: [],
      typeSearchDataHotel: [],
      typeSearchDataRoom: [],
      productInfo: {},
      returnSet: [],
      startTimeDataArr: [],
      startMinuteDataArr: [],
      jgszData: [
        {
          originalPrice: '',
          salePrice: '',
          unUsedStock: '',
          stockNum: '',
        },
      ],
    }
  },
  watch: {
    'detail.linkShopId'(a, b) {
      if (a) {
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    },
  },
  mounted() {
    for (let index = 0; index < 24; index++) {
      this.startTimeDataArr.push({
        name: index,
        value: index,
      })
    }
    for (let index = 0; index < 60; index++) {
      this.startMinuteDataArr.push({
        name: index,
        value: index,
      })
    }
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/goods/farmRoom/detail?id=${id}`,
        })
        .then((res) => {
          this.storeSearch(res.linkShopName)
          this.detail = {
            ...res,
            realNameFlag: !res.realNameFlag ? [] : ['0'].includes(res.realNameFlag) ? [] : res.realNameFlag.split(','),
            realNameInput: res.realNameInput ? res.realNameInput.split(',') : [],
          }
          ;(this.jgszData = [
            {
              originalPrice: res.originalPrice,
              salePrice: res.salePrice,
              unUsedStock: res.unUsedStock,
              stockNum: '',
            },
          ]),
            this.$forceUpdate()
        })
    }
  },
  methods: {
    /** 数据查询 */
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then((e) => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    async storeSearchHotelName(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/hotel/list?name=${value}`,
      })
      this.typeSearchDataHotel = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
    },
    async storeSearchRoomName(value = '') {
      if (!this.detail.hotelId) {
        this.$message.warning('请选择关联酒店!')
        return
      }
      const searchResult = await api.command.search.call(this, {
        url: `/api/hotelRoom/list?hotelId=${this.detail.hotelId}&name=${value}`,
      })
      this.typeSearchDataRoom = searchResult.map((el) => {
        return {
          ...el,
          name: el.roomName,
          value: el.id,
        }
      })
    },
    /** end 数据查询 */

    //#region 基础设置
    getFormPz() {
      return {
        title: '配置',
        type: 'cardForm',
        data: [
          {
            name: '加入分销商品',
            type: 'switchOpen',
            cols: 2,
            key: 'joinFx',
            showText: ['开', '关'],
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 4,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '第三方票务',
            type: 'switchOpen',
            cols: 2,
            key: 'thirdTicket',
            disabled: true,
            showText: ['开', '关'],
          },

          ...this.tabFormPz(),
        ],
      }
    },
    tabFormPz() {
      if (this.detail.thirdTicket == '0') {
        return []
      } else {
        return [
          {
            name: '票务平台',
            type: 'radioButton',
            key: 'thirdPlatform',
            cols: 3,
            typeData: [
              {
                name: '自我游',
                value: '0',
              },
            ],
          },
          {
            name: '票务种类',
            type: 'row',
            rules: [
              {
                required: true,
              },
            ],
            children: [
              {
                label: '票务种类',
                type: 'input',
                cols: 22,
                key: 'thirdProductName',
                disabled: true,
                placeholder: '请点击后面按钮选择种类',
                rules: [
                  {
                    required: true,
                  },
                ],
              },
              {
                type: 'button',
                cols: 2,
                props: {
                  icon: 'menu',
                  type: 'primary',
                  on: {
                    click: () => {
                      this.showTicketKind()
                    },
                  },
                },
              },
            ],
          },
        ]
      }
    },
    showTicketKind() {
      apiTool.showModal({
        width: '50vw',
        title: '选择票务种类',
        view: Ticket,
        viewProps: {
          productIdArr: [],
        },
        success: ({ data, setHidden }) => {
          const { productNo, productName, id } = data
          this.detail.thirdProductId = productNo
          this.detail.thirdProductName = productName
          getAction('/ticket/productInfo', { id }, '/api').then((rs) => {
            const { data } = rs
            this.productInfo = data
            this.handleData(data)
          })
          setHidden()
          this.$forceUpdate()
        },
        foot: () => [],
        // foot: ({ close }) => {
        //   return <Button onClick={close}>关闭</Button>
        // }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'productName',
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShopName',
            onInputSearch: this.storeSearch,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '酒店',
            type: 'dropDownInput',
            cols: 12,
            key: 'hotelName',
            onInputSearch: this.storeSearchHotelName,
            valueKey: 'hotelId',
            inputProps: {
              placeholder: '搜索酒店',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '房间',
            type: 'dropDownInput',
            cols: 12,
            key: 'roomName',
            onInputSearch: this.storeSearchRoomName,
            valueKey: 'roomId',
            inputProps: {
              placeholder: '搜索房间',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          ...this.getType(),
        ],
      }
    },
    getType() {
      return [
        // {
        //   type: 'row',
        //   cols: 6,
        //   children: [
        //     {
        //       name: '活动日期',
        //       type: 'rangePicker',
        //       keys: ['useStartTime', 'useEndTime'],
        //       cols: 24,
        //       ranges: {
        //         最近一周: [moment(), moment().add(1, 'weeks')],
        //         最近一个月: [moment(), moment().add(1, 'month')],
        //         最近三个月: [moment(), moment().add(3, 'month')]
        //       },
        //       rules: [
        //         {
        //           required: true
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          name: 'Banner选择',
          type: 'radioButton',
          key: 'bannerType',
          cols: 12,
          defaultValue: '0',
          typeData: [
            {
              name: 'banner图片',
              value: '0',
              display: true,
            },
            {
              name: '视频地址',
              value: '2',
              display: true,
            },
            {
              name: '数字基地监控视频',
              value: '1',
              display: this.isShowSnapshot,
            },
          ].filter((e) => e.display),
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
      ]
    },
    getFormUp() {
      return {
        type: 'tabs',
        key: 'selectType',
        children: [
          {
            title: () => (
              <div>
                <span style="color:#f00">*</span>
                Banner
              </div>
            ),
            data: [
              {
                type: 'iconUpload',
                maxLength: 1,
                cols: 12,
                descTitle: 'Banner',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'bannerUrl',
                    desc: '<br /> 支持格式.png/.jpg',
                  },
                ],
                rules: [
                  {
                    required: true,
                    message: '请上传图片',
                    type: 'string',
                  },
                ],
              },
            ],
          },
          {
            title: '宣传视频',
            data: [
              {
                type: 'iconUploadVideo',
                cols: 12,
                descTitle: '宣传视频',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'videoUrl',
                    desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.linkShopId) : {}],
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false,
      }
    },
    /**
 *   render: () => {
          return (
            <a-card title="退款设置" style="margin-top:10px;margin-bottom:-10px">
              <div class="boxes">
                <div class="boxes-one">
                  <div class="boxes-one-checkbox">
                    <a-checkbox class="">支持订单确认后，有效日期前（未验证情况下）退款</a-checkbox>
                  </div>
                  <div class="con">{this.renderTableList(1)}</div>
                </div>
                <div class="boxes-one">
                  <div class="boxes-one-checkbox">
                    <a-checkbox class="">支持有效期后（未验证情况下）退款</a-checkbox>
                  </div>
                  <div class="con">{this.renderTableList(2)}</div>
                </div>
                <div class="boxes-two">
                  <label>不可退款协议</label>
                  <a-input placeholder="不可退款协议" />
                </div>
              </div>
            </a-card>
          )
        }
 */
    onAddRule(index) {
      this.returnSet.push({})
    },
    renderTableList(index) {
      return (
        <div class="data-list-box">
          <div class="item-value">
            <span>有效日期：</span>
            <a-select default-value="lucy" style="width: 175px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <a-select default-value="lucy" style="width: 130px;margin-left:15px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <span>&nbsp;&nbsp;可退款&nbsp;&nbsp;</span>
            <a-select default-value="lucy" style="width: 215px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <a-input default-value="lucy" style="width: 88px;margin-left:15px" />
            <a-button type="danger" ghost style="margin-left: 15px; width:100px">
              删除
            </a-button>
          </div>
          <a-button type="primary" style="margin-top: 15px" onClick={() => this.onAddRule(index)}>
            添加退款规则
          </a-button>
        </div>
      )
    },
    getFormOrderConfirm() {
      return {
        title: '订单确认方式',
        type: 'cardForm',
        data: [
          {
            name: '确认方式',
            type: 'radioButton',
            key: 'confirmMethod',
            cols: 12,
            typeData: [
              {
                name: '自动确认(库存为0时自动转变成人工确认)',
                value: '0',
              },
              {
                name: '人工确认',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    //#endregion 基础设置

    //#region 价格设置
    getFormJgsz() {
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'originalPrice',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>*原价</div>,
            width: 200,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                console.log('666661', data)
                records.originalPrice = data.target.value
              }
              return <a-input disabled placeholder="原价" onChange={onInput} value={text} />
            },
          },
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>*销售价</div>,
            width: 200,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.salePrice = data.target.value
              }
              return <a-input disabled placeholder="销售价" onChange={onInput} value={text} />
            },
          },
          {
            dataIndex: 'unUsedStock',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>*可售间数</div>,
            width: 200,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.unUsedStock = data.target.value
              }
              return <a-input disabled placeholder="可售间数" onChange={onInput} value={text} />
            },
          },
          {
            dataIndex: 'stockNum',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>日历价（设置后原价、销售价、可售间数不生效）</div>,
            width: 300,
            customRender: function (text, records, index) {
              return <div></div>
            },
          },
        ],
        dataSource: this.jgszData,
      }
    },
    getFormTksz() {
      return {
        title: '退款设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款方式',
            type: 'radioButton',
            key: 'refundExplain',
            cols: 6,
            typeData: [
              {
                name: '不可退',
                value: '1',
              },
              // {
              //   name: '过期不可退',
              //   value: '2'
              // },
              {
                name: '随时退',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.refundExplain !== '0',
            name: '不可退款协议',
            type: 'input',
            cols: 9,
            key: 'refundAgreement',
          },
          //   {
          //     display: true,
          //     name: '手续费开关',
          //     type: 'switchOpen',
          //     cols: 4,
          //     key: 'refundRateFlag',
          //     showText: ['开', '关']
          //   },
          //   {
          //     display: true,
          //     name: '退款手续费费率（%）',
          //     type: 'inputNumber',
          //     cols: 5,
          //     key: 'refundRate',
          //     props: {
          //       min: 0
          //     }
          //   }
        ].filter((e) => e.display),
      }
    },
    getFormXnsj() {
      return {
        title: '虚拟数据',
        type: 'cardForm',
        data: [
          {
            name: '基础销量',
            type: 'inputNumber',
            key: 'saleCount',
            cols: 6,
            props: {
              min: 0,
            },
          },
          {
            name: '基础浏览量',
            type: 'inputNumber',
            key: 'readCount',
            cols: 6,
            props: {
              min: 0,
            },
          },
          //   {
          //     name: '限购数量',
          //     type: 'inputNumber',
          //     key: 'buyCount',
          //     cols: 6,
          //     props: {
          //       min: 0
          //     }
          //   }
        ],
      }
    },
    //#endregion 价格设置

    //#region 商品详情
    getForm6() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            simulator: true,
            onWxClick: () => {
              apiTool.showModal({
                width: '800px',
                title: '微信图文采集',
                success: ({ data, setHidden }) => {
                  const { url } = data
                  postAction('/common/urlParse', { url }).then((res) => {
                    const { data } = res
                    this.detail.content = data
                    this.$forceUpdate()
                    setHidden()
                  })
                },
                formData: {},
                form: [
                  {
                    type: 'textCard',
                    text: () => {
                      return (
                        <div>
                          <p>
                            1.下方表单输入链接，可以采集微信内容链接。例如：https://mp.weixin.qq.com/s/Gm3npsJ880dngKPj1DWDjg
                          </p>
                          <p>
                            2.可以使用微信内容，135内容复制到编辑器中即可。由于图片采集需要时间，所以等待20秒再提交表单。
                          </p>
                          <p>温馨提示：视频，过于复杂的样式，背景图，可能会导致采集失败。</p>
                        </div>
                      )
                    },
                    labelCol: { span: 0 },
                    wrapperCol: { span: 24 },
                  },
                  {
                    type: 'input',
                    key: 'url',
                    placeholder: '采集微信图文内容（填写微信图文链接）',
                    labelCol: { span: 0 },
                    wrapperCol: { span: 24 },
                    maxLength: 400,
                  },
                ],
                // foot: ({ close }) => {
                //   return (
                //     <Button onClick={close} type="primary">
                //       关闭
                //     </Button>F
                //   )
                // },
              })
            },
            cols: 24,
          },
        ],
      }
    },
    //#endregion 商品详情

    //#region 高级设置
    getFormGmxz() {
      return {
        title: '购买限制',
        type: 'cardForm',
        data: [
          {
            display: true,
            type: 'row',
            cols: 12,
            children: [
              {
                name: '购买数量限制(限制条件:用户ID   不输入0代表不限制)',
                type: 'inputNumber',
                cols: 12,
                key: 'buyCount',
                props: {
                  min: 0,
                },
              },
            ],
          },
          {
            display: true,
            name: '预订时间限制',
            type: 'row',
            cols: 12,
            gutter: 6,
            children: [
              {
                type: 'text',
                value: '在提前天数当日要求在',
                cols: 5,
              },
              {
                label: '时',
                placeholder: '时',
                type: 'select',
                key: 'startTime',
                cols: 3,
                typeData: this.startTimeDataArr,
              },
              {
                type: 'text',
                value: '时，',
                cols: 1,
              },
              {
                label: '分',
                placeholder: '分',
                type: 'select',
                key: 'startMinute',
                cols: 3,
                typeData: this.startMinuteDataArr,
              },
              {
                type: 'text',
                value: '分之前预订',
                cols: 4,
              },
            ],
          },
          {
            display: true,
            name: '连住要求（预定必须连选要求的晚数才能预定）',
            type: 'inputNumber',
            key: 'nights',
            cols: 6,
            props: {
              min: 0,
            },
          },
          {
            display: true,
            label: 'empty',
            type: 'text',
            cols: 12,
          },
          {
            display: true,
            name: '用户信息',
            type: 'checkBoxButton',
            key: 'realNameFlag',
            cols: 12,
            typeData: [
              {
                name: '实名制',
                value: '1',
              },
            ],
          },
          {
            display: this.detail.realNameFlag?.length > 0,
            name: '下单时填写信息',
            type: 'checkBoxButton',
            key: 'realNameInput',
            cols: 12,
            typeData: [
              {
                name: '姓名',
                value: 'link_man',
              },
              {
                name: '电话',
                value: 'link_phone',
              },
              {
                name: '身份证',
                value: 'link_credit_no',
              },
            ],
          },
          {
            display: this.detail.realNameFlag?.length > 0,
            name: '用户信息数量',
            type: 'radioButton',
            key: 'userInfoCount',
            cols: 12,
            typeData: [
              {
                name: '只填写一个人信息',
                value: 0,
              },
              {
                name: '根据下单数量填写所有用户信息',
                value: 1,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    getFormTj() {
      return {
        title: '推荐',
        type: 'cardForm',
        data: [
          {
            name: '周边推荐（默认选择否）',
            type: 'radioButton',
            key: 'recommend',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    //#endregion 高级设置

    /** 方法 */
    handleData(info) {
      const { productName, img, marketPrice, salePrice, settlementPrice, chargeInclude, isSingle, userNote } = info
      this.detail = {
        ...this.detail,
        expenseContain: chargeInclude,
        userInfoCount: isSingle,
        changeTicketRemark: userNote,
        skuList: [
          {
            skuImgUrl: img,
            skuName: productName,
            originalPrice: marketPrice,
            salePrice: salePrice,
            jsPrice: settlementPrice,
            allStock: null,
          },
        ],
      }
      this.$forceUpdate()
    },
    /** end 方法 */

    getFoot() {
      const left = [
        {
          name: '上一步',
          display: this.activeKey != 0 && this.detail.thirdTicket != 1,
          onClick: () => (this.activeKey = this.activeKey - 1),
        },
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            let monitorCode = null
            let monitorImg = null
            if (data.monitorCode instanceof Array && data.bannerType == 1 && data.monitorCode.length > 0) {
              monitorCode = (
                data.monitorCode.find((e) => e.select) || {
                  monitorCode: '',
                }
              ).monitorCode
              monitorImg = (
                data.monitorCode.find((e) => e.select) || {
                  monitorImg: '',
                }
              ).monitorImg
            }
            let upDown = data.id ? data.upDown : '0'
            let obj = Object.assign({
              ...data,
              upDown,
              monitorCode,
              monitorImg,
              realNameFlag: data.realNameFlag && data.realNameFlag.length > 0 ? data.realNameFlag.join(',') : '0',
              realNameInput: data.realNameInput && data.realNameInput.length > 0 ? data.realNameInput.join(',') : '',
              ...this.jgszData[0],
            })
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/goods/farmRoom',
                params: obj,
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
        {
          name: '下一步',
          display: this.activeKey != 3 && this.detail.thirdTicket != 1,
          onClick: (data) => {
            let nData = Object.assign({}, data, this.detail)
            if (this.activeKey == 0) {
              if (nData.thirdTicket == 0) {
                if (!(nData.productName && nData.linkShopId && nData.hotelId && nData.roomId && nData.bannerUrl)) {
                  this.$message.warning('请完成表单填写')
                  return
                }
              }
            }
            this.activeKey = this.activeKey + 1
          },
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/goods/farmRoom/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`).then((e) => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
          display: this.detail.id,
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmRoom?id=${this.detail.id}`,
              isPost: false,
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
          display: this.detail.id,
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    renderTab() {
      if (this.activeKey === 0) {
        return [
          this.getFormPz(),
          this.getForm1(),
          this.getFormUp(),
          this.isShowSnapshot && this.getForm2Copy(),
          this.detail.thirdTicket == '0' && this.getFormOrderConfirm(),
          this.detail.thirdTicket == '1' && this.getFormXnsj(),
          this.detail.thirdTicket == '1' && this.getFormTj(),
        ]
      } else if (this.activeKey === 1) {
        return [this.getFormJgsz(), this.getFormTksz(), this.getFormXnsj()]
      } else if (this.activeKey === 2) {
        return [this.getForm6()]
      } else if (this.activeKey === 3) {
        return [this.getFormGmxz(), this.getFormTj()]
      }
    },
  },
  render() {
    return (
      <div>
        {this.detail.thirdTicket == '0' && (
          <Naves
            navData={['基础设置', '价格设置', '商品详情', '高级设置']}
            onActive={(index) => (this.activeKey = index)}
            activeCurrentKey={this.activeKey}
          />
        )}

        <DetailFormGroup
          foot={this.getFoot()}
          form={this.detail}
          typeData={{
            linkShopName: this.storeData,
            hotelName: this.typeSearchDataHotel,
            roomName: this.typeSearchDataRoom,
          }}
          data={this.renderTab()}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 230px);
}

.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.boxes {
  .data-list-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &-two {
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #e8eaec;
    padding-top: 15px;
    box-sizing: border-box;

    input {
      width: 40%;
    }
  }
  &-one {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 100px;
    padding-top: 15px;
    padding-bottom: 15px;

    &:nth-of-type(2) {
      border-top: 1px solid #e8eaec;
      box-sizing: border-box;
    }

    .con {
      width: 58%;
    }

    &-checkbox {
      width: 40%;

      & > :nth-child(1) {
        border: 1px solid #e8eaec;
        padding: 5px 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
