<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'

export default {
  props: {
    productIdArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...api.command.getState()
    }
  },
  computed: {},
  mounted() {
    api.command.getList.call(this, {
      url: '/ticket/page',
      current: 1
    })
  },
  methods: {
    sumbit() {
      let data = this.$refs.table.selectedRows
      if (data.length === 0) {
        let nData = this.tableData.filter(e => this.productIdArr.includes(e.id))
        this.$emit('success', nData)
      } else {
        this.$emit('success', data)
      }
    },
    getColumns() {
      return [
        {
          dataIndex: 'productNo',
          title: '票务ID',
          align: 'center'
        },
        {
          dataIndex: 'productName',
          title: '票务名称',
          align: 'center'
        },
        {
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '选择',
                onClick: () => {
                  this.$emit('success', records)
                }
              }
            ]
          }
        }
      ]
    },
    getHeader() {
      return [
        {
          type: 'input',
          placeholder: '输入票务id/票务名称查询',
          key: 'name'
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        showRowSelect={false}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.template-card {
  min-height: 0;
}
/deep/ .header-box {
  margin-left: 0;
}
// .table-class {
//   /deep/ .ant-table-body {
//     max-height: 70vh;
//   }
// }
/deep/.search-button {
  margin-left: -22%;
}
</style>
